import React, { useMemo } from 'react'

import Layout from './Layout'
import ThemeContext from './theme-context'

import RootRoutes from '../../routing/index'

export const withLayout = (Component, routes = RootRoutes) => (props) => {
  const isDev = useMemo(() => window.location.hostname !== 'rtw.nbcuni.com', [])

  return (
    <ThemeContext.Provider value={{ isDev }}>
      <Layout routes={routes}>
        <Component {...props} />
      </Layout>
    </ThemeContext.Provider>
  )
}

export default Layout
