import React from 'react'

import { OverrideAddToWave } from './OverrideAddToWave'
import { OverrideDeleteDailyException } from './OverrideDeleteDailyException'
import OverridePendingActivity from './OverridePendingActivity';

const Modal = ({type, ...props}) => {
    const { message:payload={} } = props;
    console.log(type, payload)
    switch (type) {
        case 'override_add_to_wave':
            return <OverrideAddToWave {...props}  message={payload.message} title={payload?.short_desc}/>
        case 'override_delete_daily_exception':
            return <OverrideDeleteDailyException {...props} message={payload.message} title={payload?.short_desc}/>
        case 'override_required': 
            return <OverridePendingActivity {...props} message={payload.message} title={payload?.short_desc}/>
        default :
            console.error('Not a valid type')
            return null;
    }
}

export { OverrideAddToWave }

export default Modal