import React from 'react'
import PropTypes from 'prop-types'
import { createMuiTheme } from '@material-ui/core/styles';
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'

import NbcuButton from '../../common/nbcu-button'

import styles from './styles.module.css'

const muiTheme = createMuiTheme({
    overrides: {
        MuiPickersDay: {
            daySelected: {
                background: 'linear-gradient(44.56deg, #9D60E9 0%, #5F1FAE 100%)',
            },
        },
    },
})

const BaseForm = ({
    children,
    disabled,
    onSubmit,
    scheduleType
}) => (
        <form
            onSubmit={onSubmit}
            className={styles.mainForm}
            noValidate
            autoComplete="off"
        >
            <ThemeProvider theme={muiTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={styles.inputContainer}>
                    {
                        children
                    }
                </div>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
            <div className={styles.actionGroup}>
                <NbcuButton disabled={disabled} data-testid={scheduleType}>
                    Schedule Update
                </NbcuButton>
            </div>
        </form>
    )

BaseForm.propTypes = {
    children: PropTypes.element.isRequired,
    disabled: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default BaseForm
