import { AssignmentReturned } from '@material-ui/icons'
import moment from 'moment'

const targetColumnMapping = {
  'SSO': 'sso_to_update',
  'Activation Date': 'enabled_date',
  'Access Granted': 'badge_status',
  'Enabled Date': 'enabled_date',
  'Disable Date': 'disabled_date',
  'Suspend Date': 'suspended_date',
  'Badge Status/ Access Granted (TRUE/ FALSE)': 'badge_status',
}

function convertSpreadSheetToUpdateRequests(
  csvData,
  createdBy,
  requiredFields
) {
  const {data, columns} = extractData(csvData);

  if (
    !matchLength(columns, requiredFields) ||
    !columns.every((column) => requiredFields.includes(column))
  ) {
    console.log(columns, requiredFields)
    throw Error(`Please download the template provided and follow instructions as listed above.`)
  }

  return formatData(data, createdBy);
}

function extractData(csvData) {
  let data = [];
  let columns = [];

  csvData.forEach((row, i) => {
    if (i === 0) {
      columns = row.map(columnName => {
        return targetColumnMapping[columnName.trim()];
      });

    } else if (row.length !== 0)  {
      let newRow = {};
      row.forEach((value, j) => {
        newRow[columns[j]] = value;
      });
      data.push(newRow);
    }
  });

  return {data, columns};
}

function formatData(data, createdBy) {
  let newData =[];
  for (let i=0; i<data.length; i++) {
    let temp = {...data[i]};

    // Convert 'TRUE' to 'enabled' and 'FALSE' to 'disabled' for each object in array
    if (!temp['badge_status'] || (typeof temp['badge_status'] === 'string' && temp['badge_status'].toUpperCase() === 'FALSE')) {
      temp['badge_status'] = 'disabled';
    } else {
      temp['badge_status'] = 'enabled';
    }

    // format dates
    if (!!temp['disabled_date'] && !!temp['enabled_date']) {
      const { enabled_date, disabled_date } = temp
      temp = {
        ...temp,
        badge_status: 'enabled',
        scheduled_effective_date: formatSpreadsheetDate(enabled_date),
        scheduled_end_date: formatSpreadsheetDate(disabled_date),
      }
    } else {
      temp = {
        ...temp,
        scheduled_effective_date: formatSpreadsheetDate(temp['enabled_date']) || formatSpreadsheetDate(temp['disabled_date'] || formatSpreadsheetDate(temp['suspended_date'])),
      }
    }

    // add created_by and update_type to objects
    temp = {
      ...temp,
      sso_to_update: !!temp.sso_to_update ? temp.sso_to_update.toString() : '',
      created_by: createdBy,
    }

    newData.push(temp);
  }

  return newData;
}

const formatSpreadsheetDate = date => {
  if(date) {
    if (date.toString().length == 5) {
      date = xlsxDateToJSTime(date)
    }

    return moment(date).format("YYYY-MM-DD")
  }
  return '';
};

function xlsxDateToJSTime(excelDate) {
  const jsTime = (excelDate - (25567 + 1)) * 86400 * 1000
  return jsTime
}

const matchLength = (arr1, arr2) => arr1.length === arr2.length

export default convertSpreadSheetToUpdateRequests
