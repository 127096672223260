import React from 'react'
import { Redirect } from 'react-router-dom'

import BadgeProcedures from '../components/BadgeProcedures'
import DepartmentReports from '../components/DepartmentReports'

export default [
  {
    icon: null,
    exact: false,
    label: '', // 'Badge Procedures',
    path: '/badge-procedures',
    component: BadgeProcedures,
  },
  // {
  //   icon: null,
  //   exact: false,
  //   path: '/department-reports',
  //   component: DepartmentReports,
  //   label: 'Report'
  // },
  {
    icon: null,
    exact: false,
    path: '/',
    component: () => <Redirect to="/badge-procedures" />,
  },
]
