import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    spinner: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '30px',
    },
  }))
  