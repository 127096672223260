import React from 'react'

import { BaseModal } from '../BaseModal'

import styles from './OverrideDeleteDailyException.module.css'

export default ({
    open,
    onConfirm,
    onClose = () => { },
    message,
    title='User already has a Daily Exception',
}) => (
        <BaseModal
            open={open}
            onClose={onClose}
            onConfirm={(e) => onConfirm(e, { 
                override_pending_activity: true
            })}
            confirmText="Delete, and add to wave"
            denyText="Cancel, keep daily exception"
        >
            <div className={styles.primaryBody} data-testid="override-add-to-wave-modal">
                <p><em>{title}</em></p>
                <p className={styles.primaryBody}>{message.replace(/\\n/g, '\n')}</p>
            </div>
        </BaseModal>
    )
