import React from 'react'

import Button from '@material-ui/core/Button'

import styles from './styles.module.css'

export default ({ disabled, children, onClick, ...props }) => (
  <Button 
    disabled={disabled}
    onClick={onClick}
    className={!disabled ? styles.submitButton : styles.submitButtonDisabled}
    variant="contained"
    type="submit"
    color="primary"
    {...props}
  >
    {children}
  </Button>
)
