import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Error from '@material-ui/icons/Error'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import UnfoldLess from '@material-ui/icons/UnfoldLess'
import UnfoldLessRounded from '@material-ui/icons/UnfoldLessRounded'
import UnfoldMoreRounded from '@material-ui/icons/UnfoldMoreRounded'
import Close from '@material-ui/icons/Close'

import styles from './schedule-submission-reviewer.module.css'

export default function ScheduleSubmissionReviewer({ submissions = [], onClose }) {
    const [grouped, setGrouped] = useState(false)

    return (
        <div className={styles.submissionReviewer} data-testid="submission-reviewer">
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {grouped &&
                                <>
                                    <TableCell colSpan={12}>Error</TableCell>
                                </>
                            }
                            {!grouped &&
                                <>
                                    <TableCell>SSO</TableCell>
                                    <TableCell>Error</TableCell>
                                </>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {!grouped && submissions?.filter(submission => submission.validation.status !== 'success' )?.map(submission => <SubmissionRow submission={submission} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const NoticeRow = ({ notice, submissions }) => {
    const [open, setOpen] = useState(false)
    return (
        <div data-testid="notice-row">
            <TableRow>
                <TableCell colSpan={1}>
                    <IconButton data-testid="notice-row-toggle" aria-label="expand row" size="small" onClick={() => {
                        console.log('CLICKED')
                        setOpen(!open)
                    }}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell colSpan={12}>
                    ({submissions?.length}) {notice}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={12} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} unmountOnExit>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        SSO
                                </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {submissions.map(submission => <TableRow data-testid="submission-row">
                                    <TableCell className={styles.submissionCell}>
                                        {submission?.scheduled ? <CheckCircle /> : <Error />}
                                        {submission?.sso_to_update}
                                    </TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </div>
    )
}

const SubmissionRow = ({ submission }) => {
    return (
        <TableRow data-testid="submission-row">
            <TableCell>
                 {submission?.sso_to_update}
            </TableCell>
            <TableCell>
            {submission?.validation?.short_desc}
            </TableCell>
        </TableRow>
    )
}