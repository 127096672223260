import React from 'react'
import PropTypes from 'prop-types'
import { Alert, AlertTitle } from '@material-ui/lab'

const AlertContainer = ({
    alert
}) => (
    <>
        {!!alert?.type && (
             <Alert data-testid={"alert_"+ alert.type} style={{ marginTop: 25 }} severity={alert.severity}>
                <AlertTitle
                    style={{ textTransform: 'capitalize' }}
                >
                     <strong>{alert.type}</strong>
                </AlertTitle>
              <span style={{ whiteSpace: 'pre-wrap'}}>
              {alert.message.replace(/\\n/g, '\n')}
              </span>
            </Alert>
        )}
    </>
)

AlertContainer.propTypes = {
    alert: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
    })
}

export default AlertContainer