import React, { useState } from 'react'


import Search from '@material-ui/icons/Search'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import { fetchData } from '../../services/user-search'
import useUser from '../../hooks/useUser'
import UserResults from '../UserResults'
import isValidSSO from '../../utils/isValidSSO'

import styles from './styles.module.css'

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '30px',
  },
  textField: {
    width: '40%',
    maxWidth: 500,
    minWidth: 250,
  },
}))

function UserSearch() {
  const classes = useStyles()
  const { username, sessionToken } = useUser()
  const [query, setQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [dataList, setDataList] = useState([])
  const [errorMsg, setErrorMsg] = useState()
  const [noticeMsg, setNoticeMsg] = useState()
  const [pending, setPending] = useState(false)
  /**
   * This will be called every time there is
   * a change in the input
   * @param {*} { target: { value } }
   */
  const onChange = ({ target: { value } }) => {
    setQuery(value)

    // const search = _.debounce(sendQuery, 300);
    // const search = sendQuery;

    // setSearchQuery((prevSearch) => {
    //   if (prevSearch.cancel) {
    //     prevSearch.cancel();
    //   }
    //   return search;
    // });
  }

  const onSubmit = (e) => {
    e.preventDefault()

    setErrorMsg('')
    setNoticeMsg('')
    setDataList([])

    const cleanQuery = query.replace(/\s/g, '').split(',').map(q => q.trim()).join(',')
    if(!cleanQuery) {
      return
    }

    setSearchQuery(cleanQuery)

    const searchTerms = cleanQuery.split(',')
    const validSearchTerms = searchTerms.map(t => {
      if (parseInt(t) && !isValidSSO(t)) {
          return false
      }
      return true
    }).every(t => !!t)

    if(validSearchTerms) {
      sendQuery(cleanQuery)
    } else {
      setNoticeMsg('Your search failed to return any results. Please check the SSO and try again')
    }
  }


  /**
   * In charge to send the value
   * to the API.
   * @param {*} value
   */
  const sendQuery = async (value) => {
    if (pending) {
      return
    }

    setPending(true)
    if (!sessionToken) {
      setErrorMsg('Missing session Token for cognito')
    }

    const { cancelPrevQuery, result, error } = await fetchData(
      username,
      value,
      {
        Authorization: sessionToken,
      }
    )
    setPending(false)
    if (cancelPrevQuery) return

    setDataList(result)
    setErrorMsg(error)
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextField
          id="search-input"
          placeholder="Search for user by SSO or Email Address"
          onChange={onChange}
          className={classes.textField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <Button type="submit" data-testid="search-submit">
                <ArrowRightAlt />
              </Button>
            ),
          }}
          inputProps={{
            'aria-label': 'search',
          }}
        />
      </form>
      <div className={styles.searchContent}>
        {pending && (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        )}
        <UserResults
          hasPreexistingError={!!errorMsg || !!noticeMsg}
          pending={pending}
          users={dataList}
          query={searchQuery}
        />
        {!!errorMsg && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>{errorMsg}</strong>
          </Alert>
        )}
        {!!noticeMsg && (
          <Alert severity="warning">
            <AlertTitle>Notice</AlertTitle>
            <strong>{noticeMsg}</strong>
          </Alert>
        )}
      </div>
    </>
  )
}

export default UserSearch
