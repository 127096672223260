const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        authState: 'SignedIn',
        user: action.payload,
      }
    case 'LOADING':
      return {
        ...state,
        authState: 'Loading',
        user: null,
      }
    case 'SIGN_OUT':
      return {
        ...state,
        authState: 'SignedOut',
        user: null,
      }

    case 'SESSION_REFRESH': 
      console.log(state, action.payload)
      return {
        ...state,
        user: {
          ...state.user,
          signInUserSession: action.payload,
        },
      }

    case 'UNAUTHORIZED':
      return {
        ...state,
        authState: 'Unauthorized',
        user: null,
      }
    default:
      return state
  }
}

export default Reducer
