import { useContext, useEffect, useCallback } from 'react'
import { Context } from '../Store'

import Amplify, { Auth, Hub } from 'aws-amplify'

import amplifyConfig from '../amplify-config'
import useInterval from './useInterval'
Amplify.configure(amplifyConfig)

console.debug(amplifyConfig)

export default () => {
  const [state, dispatch] = useContext(Context)

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('signed in!')
          dispatch({
            type: 'SET_USER',
            payload: data,
          })
          break
        case 'signOut':
          console.log('signed out')
          dispatch({ type: 'SIGN_OUT' })
          break
        case 'signIn_failure':
          console.error('failure signing in user', data)
          dispatch({ type: 'LOADING' })
          break
        case 'cognitoHostedUI':
          break
        case 'tokenRefresh':
          break
        default:
          console.warn(
            'unexpected authentication event observed: ',
            event,
            data
          )
          dispatch({ type: 'LOADING' })
      }
    })

    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(
          'Successfully authenticated user! Authenticated user object:',
          user
        )
        dispatch({
          type: 'SET_USER',
          payload: user,
        })
      })
      .catch((e) => {
        if (e === 'not authenticated') {
          if (window.location.href.endsWith('access_denied')) {
            console.log('User forbidden access')
            dispatch({ type: 'UNAUTHORIZED' })
            return
          }

          console.log('User not logged in. Redirecting to user sign in.')
          dispatch({ type: 'LOADING' })
          Auth.federatedSignIn()
        } else {
          console.error('Observed error authenticating user: ', e)
          dispatch({ type: 'LOADING' })
        }
      })
  }, [dispatch])

  const updateRefreshToken = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      return new Promise((res, rej) => {
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          dispatch({
            type: 'SESSION_REFRESH',
            payload: session,
          })

          if(!!err) {
            rej(err)
          }
        });
      })

    } catch {
      dispatch({ type: 'SIGN_OUT' })
    }
  }, [dispatch, Auth])

  // update every 2 minutes
  useInterval(updateRefreshToken, 2 * 1000* 60)

  return state
}
