import React from 'react'
import Modal from '@material-ui/core/Modal'
import Card from '@material-ui/core/Card'
import Divider  from '@material-ui/core/Divider'

import styles from './BaseModal.module.css'

export default ({
    open,
    onClose,
    onConfirm,
    children,
    confirmText = "Yes",
    denyText = "No",
}) => (
        <Modal
            open={open}
            onClose={onClose}
            className={styles.modalContainer}
        >
            <Card className={styles.cardStyles}>
                {
                    children
                }
                <div className={styles.actionGroup}>
                    <div
                        className={styles.actionBtn}
                        onClick={onClose}
                    >
                        {denyText}
                    </div>
                    <Divider orientation="vertical" />
                    <div
                        className={styles.actionBtn}
                        onClick={(e) => {
                            onConfirm(e)
                            onClose()
                        }}
                    >
                        {confirmText}
                    </div>
                </div>
            </Card>
        </Modal>
    )