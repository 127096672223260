import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import HelpIcon from '@material-ui/icons/Help'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import Popover from '@material-ui/core/Popover'
import ThemeContext from '../theme-context'
import {queryErrorCode} from '../../../services/query-errorcode'
import useUser from '../../../hooks/useUser'

import RootRoutes from '../../../routing'

import styles from './header.module.css'

const Header = ({ routes = RootRoutes, active, name, signOut }) => {
  const { sessionToken } = useUser()
  const initials = {open: false, type: null}
  const [anchorEl, setAnchorEl] = useState(null)
  const [popover, setPopOver] = useState(initials)
  const [updateNotes, setUpdateNotes] = useState("")

  const { isDev } = useContext(ThemeContext)

  const displayPopover = (e, type) => {
    setAnchorEl(e.currentTarget)
    setPopOver({type: type, open: true})
  }

  const handleClose = () => {
    setPopOver(initials);
  }

  useEffect(async () => {
    console.log('ROutes =====', routes);
    console.log('Active ====', active);
    const response = await queryErrorCode(sessionToken, 'UPDATE01')
    const message = response.result?.message;

    setUpdateNotes(message)
  }, [])

  const open = Boolean(anchorEl)

  return (
    <header
      className={isDev ? styles.headerContainerDev : styles.headerContainer}
    >
      <div className={styles.dots} />
      <h3 className={styles.logoText}>NBCUniversal</h3>
      <div className={isDev ? styles.textBackgroundDev : styles.textBackground}>
        <h1 className={styles.headerText}>
          Return to Workplace {isDev && '- DEV/ STAGING'}
        </h1>
        
        <nav className={styles.nav}>
          {routes.map((x) => (
            <Link
              to={x.path}
              key={x.path}
              className={
                active.includes(x.path) ? styles.navActive : styles.navDefault
              }
            >
              {x.label}
            </Link>
          ))}
        </nav>

      </div>
      <div className={styles.profileContainer}>
        <div className="user-column" data-testid="user-badge">
          <div onClick={(e) => {
                  displayPopover(e,"signout")
                }}>
            <AccountCircleIcon className="icons" />
            <span>{name}</span>
            <KeyboardArrowDownIcon className={(styles.downBtn, styles.icons)} />
          </div>
          <div className="user-column bottom">
            <HelpIcon className="icons" />
            <span>
              <a
                className={styles.tags}
                href="https://nbcuni.sharepoint.com/sites/rtwportal"
                target="_blank"
              >
                Support
              </a>
            </span>
          </div>
          <div className="user-column bottom">
            <CardGiftcardIcon className="icons" />
            <span>
              <a
                className={styles.tags}
                onClick={(e) => {
                  displayPopover(e,"new")
                }}
              >
                New Updates
              </a>
            </span>
          </div>
        </div>
      </div>

      <Popover
        id="popover-account"
        open={popover.open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          popover.type === "signout"
          ?
            <div className={styles.popoverContent}>
              <div onClick={signOut}>Sign Out</div>
            </div>
            :
            <div className={styles.popoverContent}>
              <h4>Latest RTW Portal Realease Notes</h4>
              {/* <p className="release">Release Date: MM/DD/YYYY</p> */}
              <div className={styles.releaseNotes}>{updateNotes?.replace(/\\n/g, '\n')}</div>
            </div>
        }
      </Popover>
      test
    </header>
  )
}

Header.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  /*
  path evaluation
  */
  active: PropTypes.string,
}

export default Header
