import React from 'react'

export default () => (
  <svg width="141px" height="115px" viewBox="0 0 141 115" version="1.1">
    <title>A84383E5-D593-4FD9-936D-7607F5ECE8CC</title>
    <defs>
      <linearGradient x1="0%" y1="100%" x2="0%" y2="0%" id="linearGradient-1">
        <stop stopColor="#ABF8FC" offset="0%"></stop>
        <stop stopColor="#01C7FE" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="0%" y1="100%" x2="0%" y2="0%" id="linearGradient-2">
        <stop stopColor="#ABF8FC" offset="0%"></stop>
        <stop stopColor="#01C7FE" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="0%" y1="100%" x2="0%" y2="0%" id="linearGradient-3">
        <stop stopColor="#ABF8FC" offset="0%"></stop>
        <stop stopColor="#01C7FE" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="Designs"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="3RTW_Badge_SubmitPerm_DT_R2"
        transform="translate(-141.000000, -418.000000)"
      >
        <g id="Subheading" transform="translate(140.000000, 421.000000)">
          <g id="Icon_Badge" transform="translate(4.000000, 0.000000)">
            <path
              d="M63.3675907,102.588235 L7.76204241,102.588235 C3.47498118,102.588235 0,99.1051204 0,94.809116 L0,7.77911933 C0,3.48311485 3.47498118,0 7.76204241,0 L118.973139,0 C123.2602,0 126.734694,3.48311485 126.734694,7.77911933 L126.734694,51.7647059 M74.1632653,24.9411765 L107.959184,24.9411765 M74.1632653,38.1176471 L99.5102041,38.1176471 M53.5102041,102.588235 L78.8571429,102.588235"
              id="Combined-Shape"
              stroke="url(#linearGradient-1)"
              strokeWidth="4.23"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M126.24054,82.2794473 L110.311675,97.9563046 C109.534028,98.7216488 108.5149,99.1044776 107.496089,99.1044776 C106.477279,99.1044776 105.458469,98.7216488 104.680503,97.9563046 L98.3089576,91.6855617 C96.7533447,90.1545598 96.7533447,87.6744809 98.3089576,86.1434791 C99.8645705,84.6124772 102.384517,84.6124772 103.94013,86.1434791 L107.496089,89.6431807 L120.609368,76.7373647 C122.164981,75.2063628 124.684927,75.2063628 126.24054,76.7373647 C127.796153,78.2683666 127.796153,80.7484454 126.24054,82.2794473 M112.653061,61.1764706 C98.6542638,61.1764706 87.3061224,72.5539529 87.3061224,86.5882353 C87.3061224,100.6222 98.6542638,112 112.653061,112 C126.652176,112 138,100.6222 138,86.5882353 C138,72.5539529 126.652176,61.1764706 112.653061,61.1764706"
              id="Fill-3"
              fill="url(#linearGradient-2)"
            ></path>
            <path
              d="M54.5177557,51.7647059 C60.94148,51.7647059 61.2133832,54.321512 61.224497,54.4978435 L61.2249582,54.5074023 C63.8843698,55.5452784 64.7755102,75.9720078 64.7755102,75.9720078 C64.7755102,75.9720078 19.7009024,76.564402 20.6684169,75.9720078 C20.6684169,75.9720078 21.3560591,61.9169259 22.6236984,58.0111063 C22.8451952,57.3286355 24.0305628,54.5586646 24.0865863,54.5074023 C25.7807632,52.8144866 28.1359372,51.7647059 30.7427068,51.7647059 L30.7427068,51.7647059 Z M42.2456432,26.3529412 C48.9845322,26.3529412 54.4489796,31.8313642 54.4489796,38.5889825 C54.4489796,45.3451064 48.9845322,50.8235294 42.2456432,50.8235294 C35.5052637,50.8235294 30.0408163,45.3451064 30.0408163,38.5889825 C30.0408163,31.8313642 35.5052637,26.3529412 42.2456432,26.3529412 Z"
              id="Combined-Shape"
              stroke="url(#linearGradient-3)"
              strokeWidth="3.76"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
