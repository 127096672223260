import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Routes from './routing'

import useAuth from './hooks/useAuth'
import useSystemError from './hooks/useSystemError'

import './app.css'
import UnsupportedBrowserBanner from './components/common/unsupported-browser-banner'
import GenericBanner from './components/GenericBanner'
import SystemErrorDialog from './components/system-error-dialog'

function App() {
  const { authState } = useAuth()
  const systemError = useSystemError()

  return ( 
    <>
      { systemError?.result?.message && <SystemErrorDialog message={systemError.result.message} /> }
      <UnsupportedBrowserBanner />
      {authState === 'Loading' && <div>loading...</div>}
      {authState == 'Unauthorized' && <div>Access Forbidden</div>}
      {authState === 'SignedIn' && (
        <>
          <Switch>
            {Routes.map((route) => (
              <Route key={route.path} exact={route.exact} path={route.path}>
                <route.component />
              </Route>
            ))}
          </Switch>
        </>
      )}
    </>
  )
}

export default App
