import React, { useState } from 'react';
// import { withRouter } from 'react-router-dom'

import { withLayout } from '../Layout'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import BadgeIcon from '../Icons/Badge'
import styles from './styles.module.css'
import { signS3Url } from '../../services/sign-s3-url'
import useUser from '../../hooks/useUser'

// const DepartmentReports = ({match, location}) => { // use this with the withRouter below in a case you need router props

const departments = [
  "Ad Sales",
  "CNBC",
  "Content Distribution",
  "Entertainment Content",
  "Entertainment Networks",
  "Filmed Entertainment Group",
  "NBC News and MSNBC",
  "NBC Sports Group",
  "Operations & Technology",
  "NBCUniversal Local",
  "Parks & Resorts",
  "Peacock",
  "Staff",
  "Telemundo Enterprises",
  "Universal Brand Development",
  "Universal Studio Group",
  "International Networks",
  "Direct-to-Customer",
].sort()

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DepartmentReports = () => {
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [signedUrl, setSignedUrl] = useState()
  const { sessionToken } = useUser()

  const handleChange = (event) => {
    setSelectedDepartment(event.target.value);
  }

  const onSubmit = async () => {
    const formattedPath = selectedDepartment.toLowerCase().replaceAll(' ', '_')

    try {
      const signedAuthAccessUrl = await signS3Url(`authorized_access/${formattedPath}.csv`, sessionToken)
      
      window.open(signedAuthAccessUrl?.result.url)
      setSignedUrl(signedAuthAccessUrl)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>

      <header className={styles.pageHeader}>
        <BadgeIcon />
        <h1 className={styles.pageTitle}>Report</h1>
      </header>

      <p className={styles.pageDescription}>
        Run a report of SSOs that are authorized to return to work and/or have active badges.
        This report will include individuals who have been added to wave, scheduled as current/future daily exceptions, and users with active badges.
      </p>
      <FormControl variant="filled">
        <InputLabel id="demo-simple-select-label">Select a Business Segment</InputLabel>
        <Select style={{ width: 300 }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedDepartment}
          onChange={handleChange}
          MenuProps={MenuProps}
        >
          {
            departments.map(dept => <MenuItem value={dept}>{dept}</ MenuItem>)
          }
        </Select>
      </FormControl>
      <Button onClick={onSubmit} className={styles.reportButton} variant="outlined">Download Report</Button>
      {
        !!signedUrl && (
          <div style={{ paddingTop: '1rem' }}>
            <a download href={signedUrl?.result?.url} target="_blank">If your download did not automatically start, click here to download.</a>
          </div>
        )
      }
    </>
  )
}

// const DepartmentReportsWithRouter = withRouter(DepartmentReports);

export default withLayout(DepartmentReports); // pass DepartmentReportsWithRouter in the withLayout() argument if working with router props