import React from 'react'

import { BaseModal } from './BaseModal'
import styles from './OverrideDeleteDailyException/OverrideDeleteDailyException.module.css'

export default ({
    open,
    onConfirm,
    onClose = () => { },
    message,
    title='User already has a Daily Exception',
    updateRequest={}
}) => {

    const confirmText = 'Delete, and use new date.'
    const denyText = 'Keep original date.'

    return (
        <BaseModal
            open={open}
            onClose={onClose}
            onConfirm={(e) => onConfirm(e, { 
                override_pending_activity: true
            })}
            confirmText={confirmText}
            denyText={denyText}
        >
            <div className={styles.primaryBody} data-testid="override-add-to-wave-modal">
                <p><em>{title}</em></p>
                <p  className={styles.primaryBody} >{message.replace(/\\n/g, '\n')}</p>
            </div>
        </BaseModal>
    )

}