import axios from 'axios'

let tokenSource

const api_uri = process.env.REACT_APP_API_URI

const headers = {
  'Content-Type': 'application/json',
}

export const signS3Url = async (objectKey, sessionToken) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.')
    }

    // save the new request for cancellation
    tokenSource = axios.CancelToken.source()

    const { data } = await axios.get(
      `${api_uri}/sign-s3-url?key=${encodeURIComponent(objectKey)}`,
      {
        headers: {
          ...headers,
          Authorization: sessionToken,
        },
      }
    )

    return { result: data }
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true }
    return {
      error: err,
      errorMessage: err.hasOwnProperty('response')
        ? err.response.data.message.toString()
        : undefined,
    }
  }
}
