import React from 'react'
import { withRouter } from 'react-router-dom'

import { withLayout } from '../Layout'

import SubNav from '../SubNav'
import BadgeIcon from '../Icons/Badge'

import { BadgeProcedures } from '../../routing'

import styles from './styles.module.css'

const Home = withRouter(({ location, match }) => (
  <>
    <header className={styles.pageHeader}>
      <BadgeIcon />
      <h1 className={styles.pageTitle}>Badging Process</h1>
    </header>

    <p className={styles.pageDescription}>
      <span>
        Request permanent or temporary badge access activation or permanent
        badge access deactivation
      </span>
      Complete this form to add an employee to a returning wave, submit a daily
      exception, or submit a badge deactivation.
    </p>

    <SubNav
      parent={match.path}
      routes={BadgeProcedures}
      currentPath={location.pathname}
    />
  </>
))

export default withLayout(Home)
