const cognito_payload = process.env.REACT_APP_COGNITO_PAYLOAD || '{}'
const cognito_env = JSON.parse(cognito_payload)
const { user_pool_id, cognito_uri, user_pool_client_id } = cognito_env

const environment = {
  cognitoURI: cognito_uri.replace('https://', ''),
  appURI: window.location.origin, //process.env.REACT_APP_APP_URI,
  cognitoUserPoolId: user_pool_id,
  cognitoUserPoolWebClientId: user_pool_client_id,
  ssoSignOutURI: 'https://login.stg.inbcu.com/login/logoff.jsp',
  awsRegion: 'us-west-2',
}

const amplifyConfig = {
  Auth: {
    region: environment.awsRegion,
    userPoolId: environment.cognitoUserPoolId,
    userPoolWebClientId: environment.cognitoUserPoolWebClientId,
    mandatorySignIn: true,
    oauth: {
      domain: environment.cognitoURI,
      redirectSignIn: environment.appURI,
      redirectSignOut: environment.ssoSignOutURI,

      // scope: ['aws.cognito.signin.user.admin'],
      scope: ['openid', 'email', 'aws.cognito.signin.user.admin'],
      responseType: 'code',
    },
  },
}

export default amplifyConfig
