import React, { useContext } from 'react'

import styles from './footer.module.css'

import ThemeContext from '../theme-context'

export default () => {
  const { isDev } = useContext(ThemeContext)

  return (
    <footer className={styles.footer}>
      <div className={isDev ? styles.textGroupDev : styles.textGroup}>
        <h3 className={styles.logoText}>NBCUniversal</h3>
        <h2 className={styles.appName}>Return to Workplace</h2>
        <h3 className={styles.copyright}>© 2019 NBCUNIVERSAL MEDIA, LLC.</h3>
      </div>
    </footer>
  )
}
