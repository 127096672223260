import 'react-app-polyfill/ie9'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Store from './Store'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <React.Fragment>
    <BrowserRouter>
      {/* The "Store" wraps all components that need to access global state */}
      <Store>
        <App />
      </Store>
    </BrowserRouter>
  </React.Fragment>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
