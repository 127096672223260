import axios from 'axios'

let tokenSource

const api_uri = process.env.REACT_APP_API_URI

export const queryErrorCode = async (
    sessionToken,
    codeId,
) => {
    try {
        if (typeof tokenSource !== typeof undefined) {
          tokenSource.cancel('Operation canceled due to new request.')
        }

        tokenSource = axios.CancelToken.source()
    
        const { data } = await axios.get(
          `${api_uri}/query-errorcode?errorCode=${codeId}`,
          {
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionToken,
            },
          }
        )
    
        return { result: data }
      } catch (err) {
        if (axios.isCancel(err)) return { cancelPrevQuery: true }
        return {
          error: err,
          errorMessage: err.hasOwnProperty('response')
            ? err.response?.data?.message.toString()
            : undefined,
        }
      }
}