import IconButton from '@material-ui/core/IconButton'
import React, { useState } from 'react'
import { Close } from '@material-ui/icons'
import { isSupportedBrowser } from '../../../utils'

export default (props) => {
  const [isBannerOpen, setIsBannerOpen] = useState(true)

  const closeBanner = () => {
    setIsBannerOpen(false)
  }

  return (
    !isSupportedBrowser() &&
    isBannerOpen && (
      <div
        data-testid="unsupported-browser-banner"
        className="unsupported-browser-banner text-unsupported"
      >
        <div className="content">
          <h2>
            You're using an unsupported browser, you may experience issues.
            Please reload using latest version of Chrome.
          </h2>
          <div className="close-btn">
            <IconButton data-testid="close-btn" onClick={closeBanner}>
              <Close fontSize="large"/>
            </IconButton>
          </div>
        </div>
      </div>
    )
  )
}
