import React, { useState } from 'react'
import moment from 'moment'

import useUser from './useUser'
import { scheduleStatusUpdates } from '../services/schedule-status-update'

export default () => {
    const [isLoading, setIsLoading] = useState(false)
    const { username, sessionToken } = useUser()

    const [fields, setFields] = useState({
        sso_to_update: null,
        scheduled_effective_date: moment(),
        scheduled_end_date: moment().add(1, 'days'),
        update_type: null,
        badge_status: null
    })


    const [alert, setAlert] = useState({
        severity: null,
        type: null,
        message: null  
    })

    const [modal, setModal] = useState({
        type: undefined,
        message: undefined,
    })

    const submitSchedule = async (scheduleRequests=[]) => {

        const formattedRequests = scheduleRequests.map(r => ({
            ...r,
            scheduled_effective_date: r?.scheduled_effective_date?.format('YYYY-MM-DD'),
            scheduled_end_date: r?.update_type === 'temporary' ?  r?.scheduled_end_date?.format('YYYY-MM-DD') : null,
            created_by: username
        }))
        if (isLoading) {
            return
        }
        setAlert(null)
        setIsLoading(true)
        try {
            const {
                error,
                cancelPrevQuery,
                errorMessage,
                result,
            } = await scheduleStatusUpdates(username, formattedRequests, sessionToken)

            if (cancelPrevQuery) return

            if (error) {
                setAlert({
                    type: 'error',
                    severity: 'error',
                    message: errorMessage || error.message
                })
            } else {
                const bulk_request = result?.length > 1

                if(!bulk_request) {
                    const [schedule_request_response] = result
                    const { override_required, validation } = schedule_request_response
                    
                    if (override_required) {
                        setModal({
                            type: 'override_required',
                            message: validation
                        })
                    }

                    setAlert({
                        severity: validation?.status || 'error', 
                        type: validation?.short_desc || 'Whoops,',
                        message: validation?.message || 'Please contact rtw.support@nbcuni.com'
                    })

                    setIsLoading(false)
                    return
                }
            }
        } catch (err) {
            setAlert({
                type: 'Error',
                severity: 'error',
                message: `Your request failed. Please contact rtw.support@nbcuni.com for assistance.`,
            })
        }

        setIsLoading(false)
    }

    const setField = (field, value) => {
        setFields((prev) => ({
            ...prev,
            [field]: value,
        }))
    }

    return {
        submitSchedule,
        isLoading,
        setField,
        fields,
        setModal,
        alert,
        modal
    }
}