import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';


interface SystemErrorDialogProps {
    message: string
}

const useStyles = makeStyles(() => ({
    title: {
        paddingBottom: '0px',
        '& h2': {
            textAlign: 'center'
        }
    },
    message: {
        fontSize: '1.4rem',
        marginTop: 0,
        textAlign: 'center'
    }
}))

export default function SystemErrorDialog(props: SystemErrorDialogProps) {
    const { message } = props;
    const classes = useStyles()
    const [showDialog, setShowDialog] = useState(!!message)
    const open = !!showDialog && !!message
    if(!open) return null
    return (
        <Dialog open={open} onBackdropClick={_ => {}} onClose={() => console.log('closing')}>
            <DialogTitle className={classes.title}>
                <span>RTW System Warning</span>
            </DialogTitle>
            <DialogContent>
                <p className={classes.message}>
                { message } 
                </p>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setShowDialog(false)}>Dismiss alert</Button>
            </DialogActions>
        </Dialog>
    )
}