import React from 'react'

import { BaseModal } from '../BaseModal'

import styles from './OverrideAddToWave.module.css'

export default ({
    open,
    onConfirm,
    onClose = () => { },
    title,
    message='',
}) => (
        <BaseModal
            open={open}
            onClose={onClose}
            onConfirm={(e) => onConfirm(e, { override_pending_activity: true })}
            confirmText="Use New Date"
            denyText="Keep Original Date"
        >
            <div data-testid="override-add-to-wave-modal">
                <p className={styles.primaryBody}><em>Warning: </em>{title}</p>
                <p className={styles.pre}>
                    {message.replace(/\\n/g, '\n')}
                </p>
                <p>
                    Would you like to keep the original date or use new date?
                </p>
            </div>
        </BaseModal>
    )