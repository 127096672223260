import { useContext, useMemo } from 'react'

import { Context } from '../Store'

const getAttributes = (user) => {
  if (!user?.attributes) {
    return {
      given_name: '',
      family_name: '',
    }
  }

  const { attributes } = user
  return attributes
}

export default () => {
  const [{ user }] = useContext(Context)

  const username = useMemo(() => user?.username?.replace('nbcu_', ''), [user?.username])
  const { given_name, family_name } = getAttributes(user)
  const sessionToken = useMemo(() => {
    const { signInUserSession={} } = user || {};
    const { idToken={} } = signInUserSession;
    const { jwtToken } = idToken;
    return jwtToken
  }, [user?.signInUserSession])

  return {
    username,
    sessionToken,
    fullName: `${given_name} ${family_name}`,
  }
}
