import React, { useContext } from 'react'

import Header from './Header'
import { withRouter } from 'react-router-dom'
import Footer from './Footer'

import { Auth } from 'aws-amplify'

import useUser from '../../hooks/useUser'
import { Context } from '../../Store'

import styles from './layout.module.css'

export default withRouter(({ history, children, location, routes }) => {
  const user = useUser()
  console.log(Auth)
  const [_, dispatch] = useContext(Context)

  const signOut = () => {
    Auth.signOut()
  }

  return (
    <div className={styles.pageContainer}>
      <Header
        name={user?.fullName}
        routes={routes}
        active={location.pathname}
        signOut={signOut}
      />
      <main className={styles.main}>
        <section  data-testid="layout-mainContainer"  className={styles.mainContainer}>{children}</section>
      </main>
      <Footer />
    </div>
  )
})
