import { parse } from 'papaparse'

export default (source, { complete, error, ...rest } = {}) =>
  new Promise((resolve, reject) =>
    parse(source, {
      complete: (results) => resolve(results),
      error: (error) => reject(error),
      ...rest,
    })
  )
