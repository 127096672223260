import { useEffect, useState } from 'react'
import useUser from './useUser'

import { queryErrorCode } from '../services/query-errorcode'

export default () => {
    const { sessionToken } = useUser()
    const [systemError, setSystemError] = useState()

    const fetchSystemError = async () => {
        const response = await queryErrorCode(sessionToken, 'OUT00')
        setSystemError(response)
    }

    useEffect(() => {
        if (sessionToken !== undefined) {
            fetchSystemError()
        }
    }, [sessionToken])

    return systemError
}