import React, { useMemo } from 'react'
import { Link, Switch, Route } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

import styles from './styles.module.css'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'

export default ({ parent, currentPath, routes }) => {
  const firstRoute = useMemo(() => routes[0], [routes])
  const currentRoute = useMemo(() => {
    const foundRoute = routes.find(
      (route) => `${parent}${route.path}` === currentPath
    )
    return !!foundRoute ? foundRoute : firstRoute
  })

  return (
    <>
      <header className={styles.header}>
        {routes.map((route) => (
          <div
            className={
              currentPath === `${parent}${route.path}`
                ? styles.activeNav
                : styles.inactiveNav
            }
          >
            <label
              key={route.path}
              className={
                currentPath === `${parent}${route.path}`
                  ? styles.navActive
                  : styles.navDefault
              }
            >
              <Link key={route.label} to={`${parent}${route.path}`}>
                {route.label}
                <span className={styles.labelExta}>{route.labelExtra}</span>
              </Link>
            </label>
          </div>
        ))}
      </header>
      {!!currentRoute.header && (
        <h2 className={styles.headerLabel}>{currentRoute.header}</h2>
      )}

      {!!currentRoute && currentRoute.description && (
        <div className={styles.description}>
          <Typography variant="body1" gutterBottom>
            {currentRoute.description}
          </Typography>
        </div>
      )}
      <Switch>
        {currentPath === parent && (
          <Redirect to={`${parent}${firstRoute.path}`} />
        )}
        {routes.map((route) => (
          <Route exact path={`${parent}${route.path}`} key={route.label}>
            <route.component />
          </Route>
        ))}
      </Switch>
    </>
  )
}
