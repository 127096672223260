import React from 'react'
import Typography from '@material-ui/core/Typography'

import BatchUpdate from '../../components/ScheduleStatusUpdateBulk'

import styles from './DailyExceptionBatchUpdate.module.css'

export default () => (
    <>
        <BatchUpdate
            updateType="dailyException"
            requiredFields={['sso_to_update', 'enabled_date', 'disabled_date']}
        />
    </>
)